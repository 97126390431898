import React from "react";

function Damage() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <g clipPath="url(#clip0_15_84)">
                <path
                    fill="#000"
                    d="M56.459 0c3.043.91 6.31 1.39 9.091 2.812 8.864 4.54 13.465 11.922 13.746 21.963.355 12.402-4.48 22.436-13.753 30.464-5.155 4.466-9.11 9.714-11.463 16.192-1.643 4.512-5.134 7.111-9.77 8.213-.46.107-.91.235-1.366.356h-3.556c-3.466-.864-6.592-2.28-8.74-5.333a14.005 14.005 0 01-2.645-7.225c-.092-1.39.278-2.603 1.874-2.685 1.494-.074 1.942 1.014 2.07 2.35.455 4.861 3.676 8.335 8.177 8.89 4.502.554 8.765-2.07 10.436-6.529A39.086 39.086 0 0161.65 53.43c5.845-5.088 10.752-10.883 12.615-18.677 1.209-5.063 1.714-10.183.355-15.289-2.506-9.415-11.616-16.057-21.088-15.502-10.172.594-18.375 7.968-19.872 17.88-.149.993 0 2.077-.355 2.973-.32.807-1.031 1.842-1.757 2.02-1.187.295-2.104-.711-2.033-1.895.142-2.23.27-4.53.92-6.642C33.569 8.11 40.509 2.15 50.998.299c.402-.071.793-.21 1.188-.32L56.459 0z"
                ></path>
                <path
                    fill="#000"
                    d="M53.056 10.635c5.333 0 9.81 3.16 11.833 8.38 4.814 12.416 1.532 24.533-9.28 32.996-3.232 2.528-7.001 4.387-10.578 6.467-1.212.711-2.62 1.142-3.51-.558-.618-1.184 0-2.219 1.832-3.04 6.144-2.78 11.616-6.44 15.534-12.06 5.216-7.467 5.13-15.439 1.657-23.488-1.422-3.346-4.373-4.957-8.067-4.68-3.556.27-6.376 1.917-7.723 5.37-1.348 3.452-.48 6.623 1.884 9.41 1.373 1.622 2.987 3.09 4.103 4.869 3.133 4.977 1.017 11.864-4.266 14.456-1.042.512-2.183.523-2.607-.55-.288-.737.029-1.871.413-2.66.306-.633 1.159-.982 1.707-1.518 2.787-2.728 3.025-5.611.647-8.715a32.57 32.57 0 00-2.688-2.845c-3.648-3.719-4.815-9.461-2.866-14.158 1.948-4.697 6.599-7.68 11.975-7.676z"
                ></path>
                <path
                    fill="#C5CD91"
                    d="M18.012 36.508c-.55 1.423-1.105 2.827-1.65 4.246-.764 1.984-1.422 4.021-2.3 5.952-.757 1.66-2.098 2.727-4.042 2.713-1.874 0-3.2-1.067-3.876-2.67-1.067-2.44-1.88-4.978-2.805-7.467-.512-1.38-.932-2.745-2.528-3.446-.502-.22-.943-1.472-.775-2.072.167-.601 1.102-1.263 1.777-1.344 1.824-.217 3.047.98 3.78 2.45.896 1.777 1.518 3.715 2.243 5.589.63 1.621 1.234 3.25 1.85 4.878h.632c.445-.989.946-1.956 1.323-2.969.807-2.162 1.422-4.395 2.343-6.514.814-1.91 2.045-3.53 4.452-3.498 2.407.032 3.555 1.72 4.359 3.626.956 2.29 1.66 4.686 2.52 7.015.3.8.75 1.543 1.387 2.845.622-1.316 1.067-2.109 1.383-2.951.871-2.33 1.487-4.779 2.6-6.983.668-1.323 1.94-2.49 3.2-3.325a1.903 1.903 0 012.844.925c.551 1.265-.11 2.076-1.237 2.74a3.698 3.698 0 00-1.423 1.654c-.69 1.632-1.152 3.357-1.777 5.014-.573 1.486-1.067 3.043-1.938 4.366-1.93 2.994-5.43 3.008-7.204-.064-1.17-2.027-1.817-4.352-2.695-6.542-.56-1.399-1.115-2.795-1.667-4.189l-.776.021z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_15_84">
                    <path fill="#fff" d="M0 0H79.314V80H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Damage;
