import React, { ReactElement } from "react";

export default function Chevron({
    className,
    fill,
}: {
    className?: string;
    fill?: string;
}): ReactElement {
    return (
        <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.4 1.4L6 0L0 6L6 12L7.4 10.6L2.8 6L7.4 1.4Z"
                fill={fill ?? "black"}
            />
        </svg>
    );
}
