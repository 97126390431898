import React from "react";

function Ringing() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="85"
            height="80"
            fill="none"
            viewBox="0 0 85 80"
        >
            <g clipPath="url(#clip0_15_97)">
                <path
                    fill="#000"
                    d="M61.45 0c3.044.91 6.312 1.39 9.092 2.812 8.86 4.537 13.465 11.922 13.746 21.967.356 12.401-4.48 22.435-13.753 30.464-5.155 4.465-9.11 9.713-11.463 16.192-1.643 4.512-5.134 7.11-9.77 8.213-.46.107-.91.235-1.366.356H44.38c-3.466-.865-6.592-2.28-8.74-5.334a14.005 14.005 0 01-2.634-7.228c-.092-1.39.278-2.603 1.874-2.685 1.493-.074 1.941 1.014 2.066 2.35.458 4.861 3.68 8.335 8.199 8.89 4.519.554 8.764-2.07 10.435-6.529A39.055 39.055 0 0166.67 53.43c5.846-5.088 10.749-10.883 12.615-18.677 1.21-5.063 1.714-10.183.356-15.289-2.489-9.415-11.613-16.057-21.085-15.502-10.172.594-18.375 7.968-19.872 17.88-.149.993 0 2.077-.355 2.973-.32.807-1.031 1.842-1.757 2.02-1.187.295-2.108-.711-2.033-1.895.142-2.23.27-4.53.92-6.642C38.593 8.11 45.533 2.15 56.022.299c.402-.071.793-.21 1.188-.32L61.45 0z"
                ></path>
                <path
                    fill="#000"
                    d="M58.048 10.635c5.333 0 9.81 3.16 11.833 8.38 4.814 12.416 1.529 24.533-9.28 32.996-3.232 2.528-7.001 4.387-10.578 6.467-1.212.711-2.62 1.142-3.51-.558-.621-1.184 0-2.219 1.832-3.04 6.144-2.78 11.616-6.44 15.534-12.06 5.216-7.467 5.13-15.439 1.657-23.488-1.444-3.346-4.373-4.957-8.068-4.68-3.555.27-6.375 1.917-7.722 5.37-1.348 3.452-.484 6.623 1.884 9.41 1.373 1.622 2.987 3.09 4.103 4.869 3.133 4.977 1.017 11.864-4.266 14.456-1.042.512-2.184.523-2.607-.55-.288-.737.029-1.871.413-2.66.306-.633 1.159-.982 1.707-1.518 2.787-2.728 3.025-5.611.647-8.715a32.57 32.57 0 00-2.688-2.845c-3.648-3.719-4.815-9.461-2.866-14.158 1.948-4.697 6.599-7.68 11.975-7.676z"
                ></path>
                <path
                    fill="#C5CD91"
                    d="M15.847 17.422c.59.551 1.34.982 1.685 1.625.537 1.01-.053 1.824-.867 2.525-2.066 1.777-4.327 3.427-6.098 5.482-8.679 10.055-8.619 23.648 0 34.055.683.822 1.422 1.597 2.133 2.39.989 1.088 1.067 2.204.214 3.097-.854.892-2.133.86-3.1-.125C4.7 61.312 1.173 55.261.266 47.982-1.312 35.31 3.979 25.71 13.995 18.361a14.235 14.235 0 011.852-.939z"
                ></path>
                <path
                    fill="#C5CD91"
                    d="M11.723 44.395c.273-8.107 3.91-14.116 10.265-18.592 1.152-.811 2.375-.598 3.068.423.693 1.02.427 2.176-.757 3.008-3.275 2.307-5.85 5.198-7.25 8.974-2.364 6.368-1 12.142 3.072 17.4.537.712 1.177 1.316 1.742 1.995.875 1.067.84 2.134-.067 2.973a2.033 2.033 0 01-2.887-.124c-3.787-3.734-6.216-8.178-6.945-13.512-.124-.903-.174-1.834-.241-2.545z"
                ></path>
                <path
                    fill="#C5CD91"
                    d="M23.374 43.033c.068-3.357 2.319-7.008 6.176-9.764 1.067-.757 2.28-.572 2.976.356.697.928.526 2.204-.597 3.033-2.965 2.19-4.693 4.977-4.37 8.79.206 2.431 1.422 4.365 3.022 6.122 1.067 1.18.818 2.762-.505 3.388-.967.455-1.777.15-2.524-.562-2.581-2.481-4.188-6.247-4.178-11.363z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_15_97">
                    <path fill="#fff" d="M0 0H84.306V80H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Ringing;
