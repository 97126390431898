import { ReactElement } from "react";
import style from "./style.module.scss";
import Deafness from "../svg/Deafness";
import Damage from "../svg/Damage";
import Ringing from "../svg/Ringing";
import FeatureList from "@components/shared/featureList";

export default function About(): ReactElement {
    const icons = [
        {
            title: "Deafness",
            icon: <Deafness />,
        },
        {
            title: "Permanent Hearing Damage",
            icon: <Damage />,
        },
        {
            title: "Ringing in the Ears or Tinnitus",
            icon: <Ringing />,
        },
    ];
    return (
        <div className={style.wrapper}>
            <div className="mt-12">
                <h2 className="font-bold">
                    Understanding the Military Earplugs Lawsuit
                </h2>
                <p className="mb-10 md:mb-32">
                    For more than a decade, military personnel relied on 3M
                    Dual-Ended Combat Arms Earplugs to shield their ears from
                    high-decibel noises, including helicopter noise, gunfire,
                    and explosions. However, allegations have surfaced that 3M,
                    the earplug manufacturer, negligently designed the product
                    and omitted crucial safety warnings, resulting in permanent
                    injuries among service members, such as hearing loss and
                    tinnitus (persistent ringing in the ears).
                </p>
                <FeatureList
                    featureList={icons}
                    mainTitle="What Risks Are Associated With 3M Combat Arms Earplugs?"
                    mainDescription="Service members and combat veterans have reported multiple
                    side effects after the use of 3M Combat Arms Earplugs,
                    including:"
                    classes={{
                        mainTitleClasses: "font-bold",
                        innerSectionClasses:
                            "flex w-full justify-around flex-col md:flex-row mt-14",
                        oneBoxClasses:
                            "flex flex-col items-center mb-12 md:w-40",
                        titleClasses: "mt-2 text-center w-1/2 md:w-full",
                        iconClasses: "children-w-24 ",
                    }}
                />
            </div>
        </div>
    );
}
