import React, { CSSProperties, ReactElement, ReactNode } from "react";
import Image from "next/image";
import styles from "./styles.module.scss";
import Chevron from "@components/svg/chevron";
import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";
import { useRouter } from "next/router";

interface ListItem {
    icon?: ReactNode;
    title?: string;
    description?: JSX.Element | string;
    path?: string;
    tool?: string;
}
interface Colors {
    bgColor?: string;
    mainTitleColor?: string;
    titleColor?: string;
    mainDescriptionColor?: string;
    descriptionColor?: string;
    iconColor?: string;
    chevronColor?: string;
    toolColor?: string;
}
interface Classes {
    mainTitleClasses?: string;
    mainDescriptionClasses?: string;
    titleClasses?: string;
    descriptionClasses?: string;
    iconClasses?: string;
    innerSectionClasses?: string;
    mainSectionClasses?: string;
    oneBoxClasses?: string;
    toolClasses?: string;
}

export default function FeatureList({
    featureList,
    mainTitle,
    mainDescription,
    colors,
    classes,
}: {
    featureList?: ListItem[];
    mainTitle?: JSX.Element | string;
    mainDescription?: JSX.Element | string;
    colors?: Colors | null;
    classes?: Classes | null;
}): ReactElement {
    const outerSectionStyle = {
        "--bg": `${colors?.bgColor ?? "transparent"}`,
        "--iconColor": `${colors?.iconColor ?? "gray"}`,
    } as CSSProperties;
    const { query } = useRouter();
    return (
        <section
            style={outerSectionStyle}
            className={`mt-10 lg:mt-16 ${styles["mainSection"]} `}
        >
            <div className={classes?.mainSectionClasses ?? ""}>
                <h2
                    style={{ color: colors?.mainTitleColor ?? "black" }}
                    className={
                        classes?.mainTitleClasses ??
                        "text-center text-xl lg:text-2xl mb-14"
                    }
                >
                    {mainTitle}
                </h2>
                <p
                    style={{ color: colors?.mainDescriptionColor ?? "black" }}
                    className={classes?.mainDescriptionClasses ?? ""}
                >
                    {mainDescription}
                </p>
                <div
                    className={
                        classes?.innerSectionClasses ??
                        "lg:flex flex-wrap justify-center"
                    }
                >
                    {featureList?.map((item, index) => (
                        <div
                            key={index}
                            className={
                                classes?.oneBoxClasses
                                    ? `${classes?.oneBoxClasses} ${
                                          colors?.iconColor
                                              ? styles["item"]
                                              : ""
                                      }`
                                    : `text-center mb-12 lg:mb-20 w-full lg:w-1/3   ${
                                          colors?.iconColor
                                              ? styles["item"]
                                              : ""
                                      } `
                            }
                        >
                            {item.path ? (
                                <div
                                    className={
                                        classes?.iconClasses ??
                                        "relative mb-14 w-[190px] h-[190px]  mx-auto"
                                    }
                                >
                                    <Image
                                        src={item.path}
                                        layout="fill"
                                        alt={item.title}
                                    />
                                </div>
                            ) : (
                                <span
                                    className={
                                        classes?.iconClasses ??
                                        "children:w-12 children:h-12 children:mx-auto "
                                    }
                                >
                                    {item.icon}
                                </span>
                            )}

                            <h3
                                style={{ color: colors?.titleColor ?? "black" }}
                                className={
                                    classes?.titleClasses ??
                                    "font-medium text-lg lg:text-xl mt-6"
                                }
                            >
                                {item.title}
                            </h3>
                            <div
                                style={{
                                    color: colors?.descriptionColor ?? "black",
                                }}
                                className={
                                    classes?.descriptionClasses ??
                                    "font-light text-sm text-center w-4/5 mx-auto mt-2"
                                }
                            >
                                {item.description}
                            </div>
                            {item.tool && (
                                <a
                                    href={`/tools/${
                                        item.tool
                                    }?${gettingQueriesAsString(
                                        query,
                                        "start",
                                    )}`}
                                    className={
                                        classes?.toolClasses
                                            ? `${classes?.toolClasses} ${styles.link}`
                                            : `mt-4 flex gap-2 items-center md:mt-auto ${styles.link}`
                                    }
                                    style={{
                                        color: colors?.toolColor ?? "black",
                                    }}
                                >
                                    Details
                                    <Chevron
                                        className="transform rotate-180"
                                        fill={colors?.chevronColor}
                                    />
                                </a>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
